import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const vidtoquiz_API =
  process.env.NODE_ENV === "production"
    ? "https://vtq-backend-hono.vtq.workers.dev"
    : "http://localhost:8787";
