import habitat from "preact-habitat";
import { App } from "./app.tsx";

const { render } = habitat(App);

render({
  selector: "#vidtoquiz",
  defaultProps: undefined,
  inline: false,
  clean: false,
  clientSpecified: false,
});
