import { useEffect } from "preact/hooks";
import { vidtoquiz_API } from "../lib/utils";

const VIEW_THRESHOLD_MINUTES = 60;

const recordQuizView = async (quizId: string, sessionId: string) => {
  await fetch(`${vidtoquiz_API}/record-view`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      quizId: quizId,
      sessionId: sessionId,
    }),
  }).catch(() => {});
};

export const getSessionId = (quizId: string): string => {
  const sessionIdKey = `quizSessionId-${quizId}`;
  let sessionId = localStorage.getItem(sessionIdKey);

  if (!sessionId) {
    sessionId = crypto.randomUUID();
    localStorage.setItem(sessionIdKey, sessionId);
  }

  return sessionId;
};

export const useDebouncedView = (quizId: string) => {
  useEffect(() => {
    const currentSessionId = getSessionId(quizId);

    const observer = new IntersectionObserver(
      ([entry]) => {
        const lastViewKey = `lastViewTimestamp-${quizId}`;
        const lastViewTimestamp = localStorage.getItem(lastViewKey);
        const now = Date.now();

        if (
          entry.isIntersecting &&
          (!lastViewTimestamp ||
            now - parseInt(lastViewTimestamp, 10) >
              VIEW_THRESHOLD_MINUTES * 60000)
        ) {
          localStorage.setItem(lastViewKey, now.toString());
          recordQuizView(quizId, currentSessionId);
          observer.unobserve(entry.target);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 1,
      },
    );

    const vidtoquizWidget = document.getElementById("vidtoquiz");

    if (vidtoquizWidget) {
      observer.observe(vidtoquizWidget);
    }

    return () => {
      observer.disconnect();
    };
  }, [quizId]);
};
