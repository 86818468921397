import { PropsWithChildren } from "preact/compat";
import { useEffect, useState } from "preact/hooks";
import "./index.css";
import {
  QnaSection,
  Quiz,
  QuizWithIndex,
  quizAnswers,
} from "./components/qna-section";
import { getSessionId, useDebouncedView } from "./hooks/use-debounced-view";
import { vidtoquiz_API } from "./lib/utils";

export function App(props: PropsWithChildren<{ quizId: string }>) {
  const [styleSheetLoaded, setStyleSheetLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [quizData, setquizData] = useState<
    (Quiz & { index: number })[] | null | undefined
  >(undefined);

  const localStorageSubmissionKey = `submissionId-${props.quizId}`;

  const [submissionId, setSubmissionId] = useState<undefined | string>(
    undefined,
  );
  const [quizAnswers, setQuizAnswers] = useState<quizAnswers>({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isSubmissionLoading, setIsSubmissionLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [email, setEmail] = useState<undefined | string>(undefined);
  const [showResults, setShowResults] = useState<undefined | number>(undefined);

  const handleFormSubmission = async () => {
    if (!email || Object.values(quizAnswers).length !== quizData?.length) {
      return;
    }

    if (submissionId) {
      return;
    }

    setIsSubmissionLoading(true);

    try {
      const res = await fetch(`${vidtoquiz_API}/submit`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          quizId: props.quizId,
          email,
          answers: Object.values(quizAnswers),
        }),
      });

      const json = await res.json();

      setShowSuccess(true);
      localStorage.setItem(localStorageSubmissionKey, json.submissionId);
    } catch (error) {
      console.log("something went wrong!", error);
    } finally {
      setIsSubmissionLoading(false);
      setEmail("");
    }
  };

  const handleClickNext = async (mcq: QuizWithIndex) => {
    if (!quizAnswers.hasOwnProperty(currentIndex)) {
      return;
    }

    if (!mcq) return;

    if (mcq.index + 1 === quizData?.length) {
      let correctAnsCount = 0;

      const filteredCorrectOptionsIds = quizData.map(
        (q) => q.vidtoquiz_answer_options.find((op) => op.is_correct)?.id,
      );

      Object.values(quizAnswers).forEach((selectedOptionId) => {
        if (filteredCorrectOptionsIds.includes(selectedOptionId)) {
          correctAnsCount += 1;
        }
      });

      setShowResults(correctAnsCount);
      return;
    }

    setCurrentIndex(mcq.index + 1);
  };

  const handleClickPrev = (mcq: QuizWithIndex) => {
    if (!mcq) return;

    setCurrentIndex(mcq.index - 1);
  };

  const handleClickOption = (optionId: string) => {
    setQuizAnswers((pre) => ({
      ...pre,
      [currentIndex]: optionId,
    }));
  };

  useDebouncedView(props.quizId);

  useEffect(() => {
    const sessionId = getSessionId(props.quizId);
    const answersCount = Object.keys(quizAnswers).length;

    if (sessionId && answersCount === 1) {
      fetch(`${vidtoquiz_API}/record-session`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          quizId: props.quizId,
          sessionId: sessionId,
          startedAt: new Date().toISOString(),
        }),
      }).catch(console.error);
    }

    if (quizData && answersCount === quizData.length) {
      let correctAnsCount = 0;

      const filteredCorrectOptionsIds = quizData.map(
        (q) => q.vidtoquiz_answer_options.find((op) => op.is_correct)?.id,
      );

      Object.values(quizAnswers).forEach((selectedOptionId) => {
        if (filteredCorrectOptionsIds.includes(selectedOptionId)) {
          correctAnsCount += 1;
        }
      });

      const scorePercentage = (correctAnsCount / quizData.length) * 100;
      const formattedScore = Number(scorePercentage.toFixed(2));

      fetch(`${vidtoquiz_API}/record-session`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          quizId: props.quizId,
          sessionId: sessionId,
          completedAt: new Date().toISOString(),
          score: formattedScore,
        }),
      }).catch(console.error);
    }
  }, [quizAnswers, props.quizId]);

  useEffect(() => {
    if (process.env.NODE_ENV === "development") return;
    let head: HTMLHeadElement | undefined;
    let link: HTMLLinkElement | undefined;

    head = document.head;
    link = document.createElement("link");
    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = `https://assets.quizwizard.ai/quizwizard-widget-2.css`;
    link.onload = () => setStyleSheetLoaded(() => true);
    head.appendChild(link);

    return () => {
      if (head && link) head.removeChild(link);
    };
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const response = await fetch(`${vidtoquiz_API}?quizId=${props.quizId}`);

        if (!response.ok) {
          return;
        }

        const data = await response.json();

        setquizData((data as Quiz[]).map((x, i) => ({ ...x, index: i })));
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    const localSubmissionId = localStorage.getItem(localStorageSubmissionKey);
    if (localSubmissionId) {
      setSubmissionId(localSubmissionId);
    }
  }, [localStorageSubmissionKey]);

  if (
    (!styleSheetLoaded && process.env.NODE_ENV === "production") ||
    isLoading ||
    !quizData ||
    quizData.length === 0
  )
    return null;

  return (
    <div
      style={{ fontFamily: "Inter" }}
      className="wor-mx-auto wor-max-w-screen-xl  wor-px-4 wor-py-4 sm:wor-px-6 lg:wor-px-8"
    >
      <div className="wor-w-full    ">
        {showResults !== undefined && (
          <>
            {quizData[0].vidtoquiz_tests.show_answers && (
              <div className="wor-flex  wor-flex-col wor-gap-y-4 ">
                {quizData.map((qna, index) => (
                  <QnaSection
                    key={qna.id}
                    mcq={qna}
                    currentIndex={index}
                    quizAnswers={quizAnswers}
                    totalQuestions={quizData.length}
                    showAnswer={true}
                  />
                ))}
              </div>
            )}
            <div
              className={
                "g wor-mx-auto wor-mt-4 wor-flex  wor-h-full  wor-w-[90%] wor-max-w-screen-md wor-flex-col wor-space-y-4 wor-rounded-xl wor-p-4  wor-shadow-[0_3px_10px_rgb(0,0,0,0.2)] wor-ring-2   wor-ring-gray-300 lg:wor-w-full"
              }
              style={{
                backgroundImage: `url("/bg.jpg")`,
              }}
            >
              <div className="wor-relative wor-flex wor-min-h-[800px] wor-min-w-full wor-max-w-lg wor-flex-col  wor-items-center wor-justify-center wor-gap-4 wor-overflow-hidden wor-rounded-2xl wor-bg-white wor-shadow-sm">
                {(quizData[0].vidtoquiz_tests.show_answers || showSuccess) && (
                  <div className="wor-flex wor-w-full wor-flex-col wor-items-center wor-justify-center wor-pt-2">
                    <div
                      style={{
                        backgroundImage:
                          "repeating-radial-gradient(#161938 5px, #182030 10px, #030302 15px, #21253d 25px)",
                      }}
                      className="wor-flex wor-h-48 wor-w-48 wor-items-center wor-justify-center wor-rounded-full"
                    >
                      <div
                        className={
                          "wor-flex wor-h-24 wor-w-24 wor-items-center wor-justify-center wor-rounded-full wor-bg-gradient-to-r wor-from-slate-600 wor-to-slate-900"
                        }
                      >
                        <div className=" wor-text-white sm:wor-text-2xl ">
                          <span className="wor-text-4xl  wor-font-semibold ">
                            {showResults}
                          </span>
                          /{quizData.length}
                        </div>
                      </div>
                    </div>

                    {showResults !== 0 && (
                      <h2 className="wor-text-3xl wor-text-black">Congrats!</h2>
                    )}
                  </div>
                )}

                {!quizData[0].vidtoquiz_tests.show_answers && !showSuccess && (
                  <>
                    <div className="wor-mb-1 wor-w-full wor-text-center wor-text-sm wor-text-black sm:wor-text-2xl">
                      Want to see your quiz results? Enter your email below, and
                      we'll send them straight to your inbox!
                    </div>

                    <div className="wor-flex wor-w-[80%] wor-flex-col wor-items-start wor-justify-start">
                      <label
                        htmlFor="email"
                        className="wor-sr-only wor-text-sm wor-font-medium wor-leading-6 wor-text-gray-900"
                      >
                        Email
                      </label>
                      <div className="wor-mt-2 wor-w-full">
                        <input
                          type="email"
                          name="email"
                          id="email"
                          value={email}
                          style={{
                            borderRadius: 6,
                          }}
                          onChange={(e) => setEmail(e.currentTarget.value)}
                          className="wor-block wor-w-[95%]  wor-max-w-full wor-cursor-pointer wor-overflow-hidden  wor-border-0 wor-py-1.5 wor-text-gray-900 wor-shadow-sm wor-ring-2 wor-ring-inset wor-ring-gray-300 placeholder:wor-text-gray-400 focus:wor-ring-2 focus:wor-ring-inset focus:wor-ring-black/70 sm:wor-text-sm sm:wor-leading-6"
                          placeholder="you@example.com"
                        />
                      </div>
                      <div className="wor-mt-4 wor-flex wor-w-full wor-items-center wor-justify-end">
                        <div
                          type="button"
                          onClick={handleFormSubmission}
                          disabled={
                            isSubmissionLoading || Boolean(submissionId)
                          }
                          className="transform wor-inline-flex wor-h-11 wor-scale-100 wor-cursor-pointer wor-items-center wor-justify-center wor-rounded-md wor-border-none wor-bg-black wor-px-8 wor-text-base wor-font-medium wor-text-white wor-ring-offset-black wor-transition-all wor-duration-300 hover:wor-scale-105 hover:wor-text-gray-200 active:wor-scale-95 disabled:wor-pointer-events-none disabled:wor-opacity-50"
                        >
                          {isSubmissionLoading ? "Loading..." : "Submit"}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {quizData[0].vidtoquiz_tests.show_quizwizard_branding && (
                  <div className="py-2 wor-mt-3 wor-flex wor-w-full wor-items-center wor-justify-center wor-space-x-1 wor-text-black">
                    <div className="wor-text-lg wor-font-medium wor-text-black">
                      Powered by
                    </div>
                    <div
                      onClick={() =>
                        window.open("https://www.quizwizard.ai", "_blank")
                      }
                      className="wor-text-xl wor-font-semibold wor-text-black wor-no-underline wor-transition-all wor-duration-150 hover:wor-underline"
                      style="cursor: pointer;"
                    >
                      QuizWizard
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}

        {showResults === undefined && (
          <QnaSection
            mcq={quizData[currentIndex]}
            quizAnswers={quizAnswers}
            totalQuestions={quizData.length}
            currentIndex={currentIndex}
            handleClickOption={handleClickOption}
            handleClickNext={handleClickNext}
            handleClickPrev={handleClickPrev}
          />
        )}
      </div>
    </div>
  );
}
