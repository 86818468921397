import { cn } from "../lib/utils";
import { MoveLeft, MoveRight } from "lucide-react";
import { AnimatePresence, LayoutGroup, motion } from "framer-motion";
import { useState } from "preact/hooks";
import {} from "preact";

export type Quiz = {
  id: string;
  vidtoquiz_tests: {
    id: string;
    title: string;
    show_answers: boolean;
    show_quizwizard_branding: boolean;
  };
  question_text: string;
  vidtoquiz_answer_options: {
    id: string;
    option_text: string;
    is_correct: boolean;
  }[];
  answer_explanation?: string;
};

export type quizAnswers = Record<string, string>;

export type QuizWithIndex = Quiz & {
  index: number;
};

export const QnaSection = ({
  mcq,
  quizAnswers,
  handleClickOption,
  handleClickNext,
  currentIndex,
  totalQuestions,
  showAnswer,
  handleClickPrev,
}: {
  mcq: QuizWithIndex;
  quizAnswers: quizAnswers;
  totalQuestions: number;
  currentIndex: number;
  handleClickOption?: (optionId: string, showAnswer?: boolean) => void;
  handleClickNext?: (mcq: QuizWithIndex) => void;
  handleClickPrev?: (mcq: QuizWithIndex) => void;
  showAnswer?: boolean;
}) => {
  const [animationDirection, setAnimationDirection] = useState<
    "forward" | "backward"
  >("forward");
  return (
    <div
      className={cn(
        "wor-relative wor-mx-auto wor-flex wor-h-full wor-w-[90%] wor-max-w-screen-md  wor-flex-col wor-space-y-4 wor-rounded-xl wor-bg-gradient-to-br wor-from-transparent wor-to-gray-50  wor-p-4 wor-shadow-[0_3px_10px_rgb(0,0,0,0.2)] wor-ring-2 wor-ring-gray-300 lg:wor-w-full",
      )}
    >
      <div
        className={
          "wor-flex wor-h-full  wor-items-center wor-justify-center wor-gap-4 lg:wor-justify-between"
        }
      >
        <div className="wor-relative wor-flex  wor-min-h-[600px] wor-min-w-full wor-max-w-lg wor-flex-col wor-justify-between wor-overflow-hidden wor-rounded-2xl wor-bg-white wor-shadow-sm lg:wor-min-w-[80%]">
          <div>
            <div className="wor-rounded-sm wor-bg-white wor-font-semibold">
              <div class="wor-min-h-24 wor-flex wor-items-center wor-justify-start wor-rounded-t-xl wor-bg-black wor-p-5 wor-text-lg wor-text-white lg:wor-text-2xl">
                {mcq.question_text}
              </div>
            </div>
            <div className="">
              <motion.div
                initial="hidden"
                animate="visible"
                className="wor-grid wor-grid-cols-1   wor-flex-col wor-gap-6 wor-border-2 wor-px-4 wor-pb-12 wor-pt-4"
              >
                <AnimatePresence mode="wait">
                  {mcq.vidtoquiz_answer_options &&
                    mcq.vidtoquiz_answer_options.map((option, index) => (
                      <motion.div
                        key={option.id}
                        custom={index}
                        variants={{
                          hidden: {
                            opacity: 0,
                            x: -100,
                          },
                          visible: {
                            opacity: 1,
                            x: 0,
                            transition: {
                              delay: index * 0.1,
                            },
                          },
                        }}
                        whileHover={{ scale: 0.98 }}
                        whileTap={{ scale: 0.95 }}
                        exit={{
                          opacity: 0,
                          x:
                            animationDirection === "forward"
                              ? ["0", "100%"]
                              : ["0", "-100%"],
                          transition: {
                            delay: index * 0.1,
                            type: "tween",
                            duration: 0.4,
                            ease: "easeInOut",
                          },
                        }}
                        className={cn(
                          "wor-min-h-20 wor-flex wor-cursor-pointer wor-items-center wor-space-x-4  wor-rounded-md wor-px-4  wor-py-4  ",
                          quizAnswers && quizAnswers[currentIndex] === option.id
                            ? "wor-ring-[2px] wor-ring-black"
                            : "wor-ring-2 wor-ring-black/40",
                        )}
                        onClick={() =>
                          handleClickOption && handleClickOption(option.id)
                        }
                      >
                        <div className="wor-flex wor-flex-none wor-items-start wor-justify-center wor-rounded  wor-font-semibold">
                          <label
                            htmlFor={`option-${option.id}`}
                            className={cn(
                              " wor-flex wor-h-12 wor-w-12 wor-items-center wor-justify-center wor-rounded-full wor-text-2xl wor-transition-all wor-duration-500  wor-ease-in-out ",
                              quizAnswers &&
                                quizAnswers[currentIndex] === option.id
                                ? "wor-bg-black wor-text-white"
                                : "wor-bg-gray-400 wor-text-white",
                            )}
                          >
                            <span className="">
                              {String.fromCharCode(65 + index)}
                            </span>
                          </label>
                        </div>
                        <div
                          className={cn(
                            " wor-break-words  wor-font-bold lg:wor-text-xl",
                            showAnswer &&
                              mcq.vidtoquiz_tests.show_answers &&
                              option.is_correct &&
                              "wor-text-green-700",

                            quizAnswers &&
                              quizAnswers[currentIndex] === option.id
                              ? "wor-text-slate-700"
                              : "wor-text-gray-400",
                          )}
                        >
                          {option.option_text}
                          {showAnswer &&
                            mcq.vidtoquiz_tests.show_answers &&
                            option.is_correct && (
                              <span className="wor-font-semibold wor-text-green-700">
                                : Correct Answer
                              </span>
                            )}
                        </div>
                      </motion.div>
                    ))}
                </AnimatePresence>
              </motion.div>
            </div>
          </div>

          <div
            className={cn(
              "wor-flex   wor-h-full wor-w-full wor-flex-col-reverse wor-items-center wor-justify-between wor-gap-2 lg:wor-flex-row lg:wor-gap-0",
              currentIndex == 0 && "lg:wor-justify-end",
            )}
          >
            {!showAnswer && (
              <div
                role="button"
                className={cn(
                  "wor-group wor-flex wor-h-12 wor-w-full wor-transform wor-cursor-pointer wor-items-center wor-justify-center  wor-gap-4 wor-space-y-1  wor-border-none wor-bg-slate-100 wor-p-2 wor-text-lg wor-font-bold  wor-uppercase wor-text-black wor-ring-offset-black wor-transition-all wor-duration-300 lg:wor-w-1/2 ",
                  currentIndex <= 0 ? "wor-hidden" : "wor-flex",
                )}
                onClick={async () => {
                  setAnimationDirection("backward");
                  await new Promise((resolve) => setTimeout(resolve, 0));
                  handleClickPrev && handleClickPrev(mcq);
                }}
              >
                <span
                  className={
                    "wor-transition-all wor-duration-300 wor-ease-out group-hover:wor--translate-x-4"
                  }
                >
                  <MoveLeft />
                </span>
                Last Question
              </div>
            )}
            {!showAnswer && (
              <div
                role="button"
                className={cn(
                  "wor-group wor-flex wor-h-12 wor-w-full wor-scale-100  wor-transform wor-cursor-pointer wor-items-center wor-justify-center wor-gap-4 wor-space-y-1 wor-border-none wor-bg-black wor-p-2   wor-text-lg wor-font-bold wor-uppercase wor-text-white  wor-ring-offset-black wor-transition-all wor-duration-300 disabled:wor-pointer-events-none disabled:wor-opacity-50 lg:wor-w-1/2",
                  !quizAnswers.hasOwnProperty(currentIndex) &&
                    "wor-cursor-not-allowed ",
                )}
                onClick={async () => {
                  setAnimationDirection("forward");
                  await new Promise((resolve) => setTimeout(resolve, 0));
                  handleClickNext && handleClickNext(mcq);
                }}
              >
                <div>Next Question</div>

                <div
                  className={
                    "wor-transition-all wor-duration-300 wor-ease-out group-hover:wor-translate-x-4"
                  }
                >
                  <MoveRight />
                </div>
              </div>
            )}
          </div>
        </div>

        <div
          className={
            "wor-hidden wor-flex-col wor-items-center wor-justify-between wor-gap-4 wor-overflow-hidden lg:wor-flex"
          }
        >
          <LayoutGroup>
            {Array.from({
              length: totalQuestions > 6 ? 6 : totalQuestions,
            }).map((_, i) => {
              const questionNumber =
                (currentIndex > 5 ? currentIndex - 5 : 0) + i + 1;
              return (
                <motion.div
                  key={i}
                  layout
                  transition={{ duration: 0.2 }}
                  className={cn(
                    "wor-flex wor-w-full wor-items-center wor-justify-between wor-p-1",
                  )}
                >
                  <motion.div
                    layout
                    className={cn(
                      "wor-flex wor-items-center wor-justify-center wor-rounded-full wor-text-2xl  wor-text-white",
                      currentIndex === questionNumber - 1
                        ? "wor-h-16 wor-w-16 wor-bg-black "
                        : "wor-h-12 wor-w-12 wor-bg-gray-400",
                    )}
                  >
                    {questionNumber}
                  </motion.div>
                  <motion.div layout>
                    {currentIndex === questionNumber - 1 && (
                      <motion.div
                        layout
                        className={cn(
                          "wor-h-1 wor-w-12 wor-rounded-full  wor-bg-black",
                        )}
                      ></motion.div>
                    )}
                  </motion.div>
                </motion.div>
              );
            })}
          </LayoutGroup>
        </div>
      </div>

      <div className="wor-flex wor-w-full wor-flex-col wor-items-center wor-justify-between md:wor-flex-row">
        {!showAnswer && (
          <div
            className={" wor-flex wor-items-center wor-rounded-md "}
            style={{ padding: "0.5rem" }}
          >
            <div className={"wor-text-xl wor-font-semibold wor-text-black"}>
              Total Questions:
            </div>
            <div className={"wor-text-xl wor-font-semibold wor-text-black"}>
              {totalQuestions}
            </div>
          </div>
        )}
        {mcq.vidtoquiz_tests.show_quizwizard_branding && !showAnswer && (
          <div className="wor-my-1 wor-flex wor-items-center wor-space-x-1 wor-text-xl wor-text-black ">
            <div className="wor-font-medium wor-text-black">Powered by</div>
            <div
              onClick={() =>
                window.open("https://www.quizwizard.ai/", "_blank")
              }
              className="wor-font-semibold wor-text-black wor-no-underline wor-transition-all wor-duration-150 hover:wor-underline"
              style="cursor: pointer;"
            >
              QuizWizard
            </div>
          </div>
        )}

        {showAnswer && (
          <div className="wor-flex wor-flex-col wor-gap-y-2 wor-p-4">
            <div className="wor-text-base wor-font-semibold">
              Answer Explanation:
            </div>
            <div className="wor-text-sm wor-font-light wor-text-gray-900">
              {mcq.answer_explanation}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
